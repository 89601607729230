<template>
  <div id="app">
    <img class="logo" alt="Vue logo" src="./assets/simpsonator.jpg">
    <div>
      Watch a random Simpson episode like on TV
    </div>
    <Randomizer />
    <div class="footer">
      created by <a href="https://twitter.com/nicofoc" target="_blank">@nicofoc</a>
    </div>
    </div>
</template>

<script>
import Randomizer from './components/Randomizer.vue'

export default {
  name: 'App',
  components: {
    Randomizer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.logo{
  width: 100%;
  height: 100%;
  max-width: 500px;
}
.footer{
  font-size: 12px;
  color:#999;
}
.footer a{
  color:#999;
}
</style>
