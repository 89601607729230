<template>
  <div class="body">
    <div class="filters"> 
      <span>Use filters:</span>
       <div v-for="(filt, index) of filterList"  :key="index" v-on:click="indexFilter=index" v-bind:class="{ filterActive: indexFilter==index, filter: indexFilter!=index }" >
        {{ filt.name }} <span v-if="filt.editable" v-on:click="openFilter(index)">🖉</span>
      </div>
      <div class="filter" v-on:click="openFilter(-1)" >
        +
      </div>
    </div>
    <div class="random" v-on:click="randomize()">
      Randomize
    </div>
    <div v-if="errorRes" class="result-error">
      No result found with the current filter
    </div>
    <div v-if="!showRes" class="empty">
    </div>
    
    <div v-if="showRes" class="episode-card">
      <img :src="img" class="thumb">
      <div class="episode-info"> 
        <h3>Season:{{season}} Episode:{{number}}</h3>
        <h2>{{title}}</h2>
        <div  class="imdb" > <img src="imdb.jpg"/> <span>{{rating}}</span></div>
        <a :href="did" class="watch" target="_blank">
            <span>Watch on Disney+</span>
        </a>
      </div>
    </div>
    <div v-if="showEditFilter" class="edit-filter">
      <div class="edit-filter-window" >
        <div class="header-edit"><div class="close-edit" v-on:click="showEditFilter=false"> X</div></div>
        <div class="body-edit">
            <div class="body-edit-item">
                <input v-model="editFormInfo.name" placeholder="Name" v-bind:class="{ nameError: editFormInfo.nameError}">
            </div>
            <div class="body-edit-item">
                <span>min. IMDb Score</span> <input v-model.number="editFormInfo.rating" type="number" min="0" max="10" step="0.1">
            </div>
            <div >
                <span>Select Seasons:</span>
            </div>
            <div class="body-edit-item seasons-edit">
                 <div v-for="(selected, index) of editFormInfo.seasons"  :key="index" class="season-edit">
                  <input type="checkbox"  v-model="editFormInfo.seasons[index]"> {{index +1}}
                </div>
            </div>
        </div>
        <div class="footer-edit">

          <div class="footer-edit-left">
            <div class="edit-button-delete"  v-if="editFormInfo.index != -1" v-on:click="deleteFilter()">
              Delete
            </div>
          </div>
          <div class="footer-edit-right">
            <div class="edit-button" v-on:click="saveFilter()">
              Save
            </div>
            <div class="edit-button" v-on:click="showEditFilter=false">
              Cancel
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

let episodes = {};
let numSeasons = 0;
let filtersBase = [{
  name: "All Seasons",
  imdbFilter: 0,
  removedSeasons: [],
  editable: false
},
{
  name: "The Best Episodes",
  imdbFilter: 8.5,
  removedSeasons: [],
  editable: false
},
{
  name: "Classic Episodes",
  imdbFilter: 0,
  removedSeasons: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
  editable: false
}
];

export default {
  name: 'Randomizer',
  props: {
    //msg: String
  },
  data: function () {
    return {
      showRes: false,
      errorRes: false,
      title: "",
      rating: 0,
      img: "",
      did:"",
      season: 0,
      number: 0,
      filterList: filtersBase.slice(),
      customFilters: [],
      indexFilter: 0, 
      showEditFilter: false,
      editFormInfo:{
        name: "",
        nameError: false,
        rating: 0,
        seasons: [],
        index: -1
      }

    }
  },
  created() {
    console.log('Component has been created!', localStorage.customFilters);
    console.log(localStorage.customFilters);
    if (!localStorage.customFilters) return;
    this.customFilters = JSON.parse(localStorage.customFilters)["fl"];
    for (let i = 0 ; i < this.customFilters.length; i++) this.filterList.push(this.customFilters[i]);
  },
  methods: {
        
        randomize: function () {
            this.errorRes = false;
            const episode = getChapter( this.filterList[this.indexFilter].imdbFilter, this.filterList.[this.indexFilter].removedSeasons);
            if(!episode){
              this.errorRes = true;
              return;
            }
            this.title = episode["title"];
            this.rating = episode["rating"];
            this.img = episode["img"];
            this.did = "https://www.disneyplus.com/video/" + episode["disneyId"];
            this.season = episode["season"];
            this.number = episode["episode"];
            this.showRes = true;
        },
        openFilter: function (filterIndex) {
            if (filterIndex!=-1) {
              this.editFormInfo.name = this.filterList[filterIndex].name;
              this.editFormInfo.rating = this.filterList[filterIndex].imdbFilter;
              console.log(this.editFormInfo.rating);
              this.editFormInfo.seasons = [];
              this.editFormInfo.index= filterIndex
              for(let i = 0; i< numSeasons; i++) this.editFormInfo.seasons.push(this.filterList[filterIndex].removedSeasons.indexOf(i+1) == -1 ?true : false);
            } else {
              this.editFormInfo.name = "";
              this.editFormInfo.rating = 0;
              this.editFormInfo.seasons = [];
              this.editFormInfo.index= -1
              for(let i = 0; i< numSeasons; i++) this.editFormInfo.seasons.push(true);
            }
            this.editFormInfo.nameError = false;
            this.showEditFilter= true;
        },
        deleteFilter: function () {
            if (this.indexFilter == this.editFormInfo.index) this.indexFilter =0;
            this.filterList.splice(this.editFormInfo.index, 1);
            this.customFilters.splice(this.editFormInfo.index - filtersBase.length, 1);
            localStorage.setItem('customFilters', JSON.stringify({fl:this.customFilters}));
            this.showEditFilter= false;
        },
        saveFilter: function () {
            if (this.editFormInfo.name === "") {
              this.editFormInfo.nameError = true;
              return;
            }
            let filter = {
              name: this.editFormInfo.name,
              imdbFilter: this.editFormInfo.rating,
              removedSeasons: [],
              editable: true
            };
            for(let i = 0; i< numSeasons; i++) {
              if (!this.editFormInfo.seasons[i]) {
                filter.removedSeasons.push(i+1);
              }
            }
            if (this.editFormInfo.index == -1){
              this.filterList.push(filter);
              this.customFilters.push(filter);
            } else {
              console.log(filter);
              this.filterList[this.editFormInfo.index] = filter;
              console.log(this.editFormInfo.index, filtersBase.length);
              this.customFilters[this.editFormInfo.index - filtersBase.length] = filter;
              console.log(this.customFilters);
            }
            localStorage.setItem('customFilters', JSON.stringify({fl:this.customFilters}));
            this.showEditFilter= false;
        }
    }
}

fetch("allEpisodes.json")
        .then(response => response.json())
        .then(data => {
          episodes = data;
          for( let ep of episodes["episodes"]) {
              if (ep.season > numSeasons) numSeasons= ep.season;
          }
        });

function getChapter(imdbFilter, listSeasonToRemove){
  let episode;
  if (listSeasonToRemove[0]){
    const filtered = episodes["episodes"].filter(ep => ((ep.rating >= imdbFilter) && ( listSeasonToRemove.indexOf(ep.season) == -1) ));
    console.log("Episodes filteres", filtered.length, listSeasonToRemove)
    episode = filtered[Math.floor(Math.random() * filtered.length)];
  } else {
    const filtered = episodes["episodes"].filter(ep => (ep.rating >= imdbFilter));
    console.log("Episodes filteres 2", filtered.length, listSeasonToRemove)
    episode = filtered[Math.floor(Math.random() * filtered.length)];
  }
    return episode;
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body{
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
}
.body div {
  align-self: center;
}

.filters
{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 15px 0px 15px 0px;
}

.filters span
{
  color: #0569D2;
  padding: 12px;
}

.filter
{
  border: 1px solid #0569D2;
  color: #0569D2;
  margin: 5px;
  padding: 10px;
  border-radius: 50px;
  text-decoration: none;
  align-items: flex-start;
  cursor: pointer;
}
.filter span  {
  color: #0569D2;
}

.random
{
  background-color: #FCD820;
  font-weight: bold;
  margin: 5px;
  padding: 15px;
   font-size: 150%;
  border-radius: 6px;
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer;
}

.filterActive {
  background-color: #0569D2;
  color: whitesmoke;
  border: 1px solid #0569D2;
  margin: 5px;
  padding: 10px;
  border-radius: 50px;
  text-decoration: none;
  align-self: flex-start;
  cursor: pointer;
}
.filterActive span  {
  color: whitesmoke;
}
.filterActive span :hover {
  background-color: whitesmoke;
  color: #0569D2;
}
.empty {
  height: 50px;
  width: 20px;
  display: block;
}
.result-error {
  font-size: 22px;
  font-weight: bold;
  color: red;
  margin: 20px;
  padding: 0px 15px 0px 10px;
}

.episode-card{
  margin: 20px;
  display: flex;
  border: 1px solid #0569D2;
  border-radius: 5px;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0px 15px 0px 10px;
}
.episode-info {
  
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-self: flex-start;
  padding: 0px 20px 25px 0px;
}
.episode-card .thumb
{
  max-height: 170px;
  padding: 25px 25px 25px 5px;
  align-self: center;
}

.episode-card h3
{
  align-self: flex-start;
  padding: 0px;
  margin: 0px 0px 0px 0px;
  font-size: 15px;
  color: gray;
}
.episode-card h2
{
  align-self: flex-start;
  padding: 0px;
  margin: 0px 0px 10px 0px;
}
.imdb{
  align-self: flex-start!important;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 15px;
  text-decoration: none;
  color:black;
}
.imdb span{
  align-self: center;
  font-weight: bold;
}
.imdb img{
  align-self: center;
  width: 25px;
  margin-right: 5px;
}
.watch
{
  color: whitesmoke;
  background-color: #0569D2;
  padding: 10px;
  border-radius: 8px;
  align-self: flex-start;
  font-weight: bold;
  text-decoration: none;
}
.watch :hover
{
  text-decoration: underline;
}

.edit-filter
{
  background-color: rgba(200, 200, 200, 0.5);
  position: absolute;
  height:100%;
  width: 100%;
  top: 0;
  display:flex;
  flex-flow: column;
}
.edit-filter-window {
  background-color:whitesmoke;
  width:100%;
  top: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 20px;
  max-width: 600px;
  padding: 10px;
}

.header-edit{
  text-align: right;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.body-edit{
  width: 100%;
  text-align: left;
  padding: 25px 20px 20px 20px;
  font-size: 18px;
}
.seasons-edit {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding:10px 10px 10px 5px;
}
.season-edit {
  padding-right: 30px;
  padding-bottom: 10px;
}
.body-edit-item{
  margin-bottom: 20px;
}
.nameError {
  border: 2px solid red;
}
.footer-edit{
  text-align: right;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.footer-edit-left{
  text-align: left;
}
.footer-edit-right{
  text-align: right;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  
}
.edit-button{
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.edit-button-delete{
  padding: 10px;
  border: 1px solid red;
  color: red;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.close-edit{
  text-align: center;
  width: 20px;
  height: 20px;
  color: gray;
  cursor: pointer;
}
</style>
