import { render, staticRenderFns } from "./Randomizer.vue?vue&type=template&id=dbfd3574&scoped=true&"
import script from "./Randomizer.vue?vue&type=script&lang=js&"
export * from "./Randomizer.vue?vue&type=script&lang=js&"
import style0 from "./Randomizer.vue?vue&type=style&index=0&id=dbfd3574&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbfd3574",
  null
  
)

export default component.exports